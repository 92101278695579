import { DomainResource, QuestionnaireAnswerOption, Reference } from './index';
import merge from 'lodash/merge';
import Coding from './types/Coding';

export default class BN_QuestionnaireField extends DomainResource {
    static __className = 'BN_QuestionnaireField';

    __objectStructure = {
        label: String,
        htmlLabel: String,
        description: String,
        type: String,
        partOf: [Reference],
        code: [Coding],
        choice: [QuestionnaireAnswerOption],
        informationText: String,
        isCarf: Boolean,
        isJointCommission: Boolean,
        isCms: Boolean,
        ehrOnly: Boolean,
    };

    __objectDefaults = {
        experimental: false,
    };

    constructor(constructJson, className = 'BN_QuestionnaireField') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson, this.__objectDefaults);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
